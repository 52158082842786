<template>
  <LayoutAuth>
    <!--step 1-->
    <div v-if="currentStep === 1" class="mx-auto max-w-7xl">
      <div class="grid text-center gap-4 my-4">
        <BaseHeading tag="h1" size="3xl" weight="bold">
          {{ $t('_pages._login._step1.title') }}
          <span class="underline underline-offset-4 decoration-4 decoration-orange-400">Winx</span> 👋
        </BaseHeading>
        <BaseHeading tag="h3">
          {{ $t('_pages._login._step1.subtitle') }}
        </BaseHeading>
      </div>

      <div>
        <div class="w-full">
          <div class="mx-auto w-full">
            <div class="w-full">
              <div class="mx-auto mb-4 grid max-w-4xl gap-2 sm:gap-6 sm:grid-cols-3">
                <BaseRadioHeadless v-model="twoFaMode" name="radio_custom" value="email">
                  <BaseCard
                    rounded="lg"
                    class="peer-checked:!border-cyan-800 dark:peer-checked:!border-primary-700 relative !border-2 p-8 opacity-60 grayscale peer-checked:opacity-100 peer-checked:grayscale-0 peer-checked:[&_.child]:!opacity-100"
                  >
                    <div class="flex flex-col text-center">
                      <BaseIconBox
                        :key="`email-${refreshIcons.email}`"
                        size="2xl"
                        rounded="full"
                        color="none"
                        bordered
                        class="mx-auto my-4 dark:bg-muted-700 text-cyan-800 dark:text-primary-700 hidden sm:flex"
                      >
                        <Icon name="line-md:email-twotone-alt" class="size-10"></Icon>
                      </BaseIconBox>
                      <BaseHeading size="md" weight="medium">
                        {{ $t('_pages._login._step1._optionEmail.title') }}
                      </BaseHeading>
                      <BaseParagraph
                        size="xs"
                        lead="snug"
                        class="hidden sm:inline-block text-muted-500 dark:text-muted-400"
                      >
                        {{ $t('_pages._login._step1._optionEmail.description') }}
                      </BaseParagraph>
                    </div>
                    <div class="child absolute end-2 top-3 opacity-0">
                      <Icon name="ph:check-circle-duotone" class="text-cyan-800 dark:text-primary-700 size-7" />
                    </div>
                  </BaseCard>
                </BaseRadioHeadless>
                <BaseRadioHeadless v-model="twoFaMode" name="radio_custom" value="phone">
                  <BaseCard
                    rounded="lg"
                    class="peer-checked:!border-cyan-800 dark:peer-checked:!border-primary-500 relative !border-2 p-8 opacity-60 grayscale peer-checked:opacity-100 peer-checked:grayscale-0 peer-checked:[&_.child]:!opacity-100"
                  >
                    <div class="flex flex-col text-center">
                      <BaseIconBox
                        :key="`phone-${refreshIcons.phone}`"
                        size="2xl"
                        rounded="full"
                        color="none"
                        bordered
                        class="mx-auto my-4 dark:bg-muted-700 text-cyan-800 dark:text-primary-700 hidden sm:flex"
                      >
                        <Icon name="line-md:chat-twotone" class="size-10"></Icon>
                      </BaseIconBox>
                      <BaseHeading size="md" weight="medium">
                        {{ $t('_pages._login._step1._optionPhone.title') }}
                      </BaseHeading>
                      <BaseParagraph
                        size="xs"
                        lead="snug"
                        class="hidden sm:inline-block text-muted-500 dark:text-muted-400"
                      >
                        {{ $t('_pages._login._step1._optionPhone.description') }}
                      </BaseParagraph>
                    </div>
                    <div class="child absolute end-2 top-3 opacity-0">
                      <Icon name="ph:check-circle-duotone" class="text-cyan-800 dark:text-primary-700 size-7" />
                    </div>
                  </BaseCard>
                </BaseRadioHeadless>
                <BaseRadioHeadless v-model="twoFaMode" name="radio_custom" value="document">
                  <BaseCard
                    rounded="lg"
                    class="peer-checked:!border-cyan-800 dark:peer-checked:!border-primary-500 relative !border-2 p-8 opacity-60 grayscale peer-checked:opacity-100 peer-checked:grayscale-0 peer-checked:[&_.child]:!opacity-100"
                  >
                    <div class="flex flex-col text-center">
                      <BaseIconBox
                        :key="`document-${refreshIcons.document}`"
                        size="2xl"
                        rounded="full"
                        color="none"
                        bordered
                        class="mx-auto my-4 dark:bg-muted-700 text-cyan-800 dark:text-primary-700 hidden sm:flex"
                      >
                        <Icon name="line-md:person-search-twotone" class="size-10"></Icon>
                      </BaseIconBox>
                      <BaseHeading size="md" weight="medium">
                        {{ $t('_pages._login._step1._optionDoc.title') }}
                      </BaseHeading>
                      <BaseParagraph
                        size="xs"
                        lead="snug"
                        class="hidden sm:inline-block text-muted-500 dark:text-muted-400"
                      >
                        {{ $t('_pages._login._step1._optionDoc.description') }}
                      </BaseParagraph>
                    </div>
                    <div class="child absolute end-2 top-3 opacity-0">
                      <Icon name="ph:check-circle-duotone" class="text-cyan-800 dark:text-primary-700 size-7" />
                    </div>
                  </BaseCard>
                </BaseRadioHeadless>
              </div>
              <div class="mx-auto flex flex-col xs:w-full items-center">
                <BaseButton
                  type="button"
                  rounded="lg"
                  class="!h-12 w-full sm:w-56"
                  color="primary"
                  :loading="loading"
                  @click="goToStep(2)"
                >
                  {{ $t('wb._action.continue') }}
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="currentStep === 2 || currentStep === 3" class="flex w-full items-center justify-center">
      <div class="relative mx-auto w-full max-w-2xl">
        <!--Avatars-->
        <NuxtImg
          class="ltablet:block absolute start-0 top-[30%] hidden size-12 rounded-full object-cover duration-[cubic-bezier(0.86,0,0.07,1)] lg:block scale-up-center"
          src="/images/avatars/1.png"
          alt="Avatar"
          width="48"
          height="48"
        />
        <NuxtImg
          class="ltablet:block absolute -start-[25%] top-[40%] hidden size-16 rounded-full object-cover lg:block scale-up-center"
          src="/images/avatars/9.png"
          alt="Avatar"
          width="64"
          height="64"
        />
        <NuxtImg
          class="ltablet:block absolute -start-[5%] top-[52%] hidden size-16 rounded-full object-cover lg:block scale-up-center"
          src="/images/avatars/4.png"
          alt="Avatar"
          width="64"
          height="64"
        />
        <NuxtImg
          class="ltablet:block absolute -start-[35%] top-[65%] hidden size-24 rounded-full object-cover lg:block scale-up-center"
          src="/images/avatars/8.png"
          alt="Avatar"
          width="96"
          height="96"
        />
        <NuxtImg
          class="ltablet:block absolute -start-[35%] top-[20%] hidden size-10 rounded-full object-cover lg:block scale-up-center"
          src="/images/avatars/12.png"
          alt="Avatar"
          width="40"
          height="40"
        />
        <NuxtImg
          class="ltablet:block absolute -start-[55%] top-[40%] hidden size-20 rounded-full object-cover lg:block scale-up-center"
          src="/images/avatars/20.png"
          alt="Avatar"
          width="80"
          height="80"
        />

        <NuxtImg
          class="ltablet:block absolute end-0 top-[30%] hidden size-12 rounded-full object-cover lg:block scale-up-center"
          src="/images/avatars/16.png"
          alt="Avatar"
          width="48"
          height="48"
        />
        <NuxtImg
          class="ltablet:block absolute -end-[25%] top-[40%] hidden size-16 rounded-full object-cover lg:block scale-up-center"
          src="/images/avatars/10.png"
          alt="Avatar"
          width="64"
          height="64"
        />
        <NuxtImg
          class="ltablet:block absolute -end-[5%] top-[52%] hidden size-16 rounded-full object-cover lg:block scale-up-center"
          src="/images/avatars/19.png"
          alt="Avatar"
          width="64"
          height="64"
        />
        <NuxtImg
          class="ltablet:block absolute -end-[35%] top-[65%] hidden size-24 rounded-full object-cover lg:block scale-up-center"
          src="/images/avatars/18.png"
          alt="Avatar"
          width="96"
          height="96"
        />
        <NuxtImg
          class="ltablet:block absolute -end-[35%] top-[20%] hidden size-10 rounded-full object-cover lg:block scale-up-center"
          src="/images/avatars/3.png"
          alt="Avatar"
          width="40"
          height="40"
        />
        <NuxtImg
          class="ltablet:block absolute -end-[55%] top-[40%] hidden size-20 rounded-full object-cover lg:block scale-up-center"
          src="/images/avatars/5.png"
          alt="Avatar"
          width="80"
          height="80"
        />

        <div class="me-auto ms-auto mt-4 w-full max-w-md">
          <div class="text-center my-4">
            <BaseHeading tag="h1" size="3xl" weight="bold">
              {{ $t('_pages._login._step1.title') }}
              <span class="underline underline-offset-4 decoration-4 decoration-orange-400">Winx</span> 👋
            </BaseHeading>
          </div>
          <!--Step 2-->
          <BaseCard v-if="currentStep === 2" class="p-4 sm:p-8">
            <div class="text-center">
              <BaseHeading v-if="twoFaMode !== 'phone'" class="mb-3" as="h2" size="2xl" weight="bold">
                {{ $t('_pages._login._step2.title', { option: `wb.${twoFaMode}` }) }}
              </BaseHeading>
              <BaseHeading v-else class="mb-3" as="h2" size="2xl" weight="bold">
                {{ $t('_pages._login._step2.title', { option: `wb.${'sms'}` }) }}
              </BaseHeading>
              <BaseParagraph v-if="twoFaMode !== 'document'" size="sm" class="text-muted-400 mb-6">
                {{ $t('_pages._login._step2.subtitleOtp', { option: `wb.${twoFaMode}` }) }}
              </BaseParagraph>
              <BaseParagraph v-else size="sm" class="text-muted-400 mb-6">
                {{ $t('_pages._login._step2.subtitle', { option: `wb.${twoFaMode}` }) }}
              </BaseParagraph>
            </div>

            <div class="mb-4">
              <BaseInput
                v-if="twoFaMode === 'email'"
                v-model="accessKey"
                type="email"
                name="email"
                icon="ph:envelope-duotone"
                rounded="lg"
                :placeholder="$t('_pages._login._step2.placeholderEmail')"
                :classes="{
                  wrapper: 'w-full',
                  input: '!h-12 !ps-12',
                  icon: '!h-12 !w-12',
                }"
              />

              <BaseInput
                v-else-if="twoFaMode === 'phone'"
                v-maska
                data-maska="(##) #####-####"
                name="phone"
                icon="ph:device-mobile-speaker-duotone"
                rounded="lg"
                :placeholder="$t('_pages._login._step2.placeholderPhone')"
                @maska="onMaska"
                :classes="{
                  wrapper: 'w-full',
                  input: '!h-12 !ps-12',
                  icon: '!h-12 !w-12',
                }"
              />

              <BaseInput
                v-else-if="twoFaMode === 'document'"
                icon="ph:fingerprint-duotone"
                name="document"
                v-maska
                data-maska="###.###.###-##"
                @maska="onMaska"
                :placeholder="$t('_pages._login._step2.placeholderDoc')"
                rounded="lg"
                :classes="{
                  wrapper: 'w-full',
                  input: '!h-12 !ps-12',
                  icon: '!h-12 !w-12',
                }"
              />
            </div>

            <div class="mb-6">
              <BaseButton type="submit" color="primary" class="!h-12 w-full" :loading="loading" @click="startSignIn">
                {{ $t('wb._action.continue') }}
              </BaseButton>
            </div>

            <div class="mb-6 grid gap-0 sm:grid-cols-3">
              <hr class="border-muted-200 dark:border-muted-700 mt-3 hidden border-t sm:block" />
              <span class="dark:bg-muted-800 text-muted-400 relative top-0.5 bg-white text-center font-sans text-sm">
                {{ $t('wb.moreInfo') }}
              </span>
              <hr class="border-muted-200 dark:border-muted-700 mt-3 hidden border-t sm:block" />
            </div>

            <div class="flex flex-col mt-4 text-center gap-2">
              <div class="flex flex-col mt-4 text-center gap-2">
                <I18nT
                  v-if="twoFaMode !== 'document'"
                  tag="p"
                  keypath="_pages._login.support"
                  scope="parent"
                  class="text-sm"
                ></I18nT>

                <I18nT tag="p" keypath="_pages._login.comments" scope="parent" class="text-sm">
                  <template #helpCenter>
                    <a
                      class="underline font-bold dark:text-primary-950"
                      href="https://suporte.winx.ai/hc/pt-br/requests/new?ticket_form_id=27020495830036"
                      target="_blank"
                    >
                      {{ $t('wb.helpCenter') }}
                    </a>
                  </template>
                </I18nT>
              </div>
            </div>

            <!--No access form-->
            <p class="text-muted-400 mt-8 flex justify-between font-sans text-sm leading-5">
              <span>{{ $t('_pages._login.changeOfAccess') }}</span>
              <button
                class="dark:text-primary-600 dark:hover:text-primary-500 font-medium underline-offset-4 transition duration-150 ease-in-out hover:underline"
                @click="goToStep(1)"
              >
                {{ $t('wb._action.clickHere') }}
              </button>
            </p>
          </BaseCard>

          <!--Step 3-->
          <BaseCard v-if="currentStep === 3" class="p-4 sm:p-8">
            <div class="text-center">
              <BaseHeading class="mb-3" as="h2" size="2xl" weight="bold">
                {{ $t('_pages._login._step3.title') }}
              </BaseHeading>
              <BaseParagraph size="sm" class="text-muted-400 mb-6">
                {{ $t('_pages._login._step3.subtitle', { option: `wb.${twoFaMode}` }) }}
              </BaseParagraph>
            </div>

            <div class="mb-4 space-y-4">
              <WinxFormOtp
                v-model:value="code"
                class="flex w-full justify-between mb-4"
                :class="loading && 'pointer-events-none'"
                input-classes="dark:bg-muted-700 unselectable nui-focus inline w-10 select-none rounded-lg bg-muted-100 py-4 text-center text-xl sm:text-4xl font-bold transition-all"
                :should-auto-focus="true"
                :input-props="{ placeholder: '-' }"
                :loading="loading"
                input-type="number"
                @on-complete="signIn"
              />

              <BaseButton
                color="primary"
                class="!h-12 w-full"
                :disabled="!isDigitsFull"
                :loading="loading"
                @click="signIn"
              >
                {{ $t('wb._action.access') }}
              </BaseButton>
            </div>

            <div class="mb-6 grid gap-0 sm:grid-cols-3">
              <hr class="border-muted-200 dark:border-muted-700 mt-3 hidden border-t sm:block" />
              <span class="dark:bg-muted-800 text-muted-400 relative top-0.5 bg-white text-center font-sans text-sm">
                {{ $t('_pages._login._step3.resendCode') }}
              </span>
              <hr class="border-muted-200 dark:border-muted-700 mt-3 hidden border-t sm:block" />
            </div>

            <div class="flex flex-col gap-4 mb-4">
              <BaseCard
                v-if="emailMask"
                rounded="lg"
                class="flex items-center gap-3 p-4"
                @click="changeChannel('email')"
              >
                <div
                  class="dark:bg-muted-900 border-muted-200 dark:border-muted-800 flex size-12 items-center justify-center rounded-xl border border-md bg-white"
                >
                  <Icon name="fa-solid:envelope" class="text-muted-800 dark:text-muted-100 size-5" />
                </div>
                <div>
                  <BaseHeading as="h4" size="sm" weight="light" lead="tight" class="text-muted-800 dark:text-white">
                    <span>{{ $t('_pages._login._step3.resendByEmail') }}</span>
                  </BaseHeading>
                  <BaseParagraph size="xs">
                    <span class="text-muted-400">{{ emailMask }}</span>
                  </BaseParagraph>
                </div>
                <div class="ms-auto flex items-center xs:hidden">
                  <BaseButtonIcon rounded="lg" muted class="scale-75">
                    <Icon name="lucide:arrow-right" class="size-5" />
                  </BaseButtonIcon>
                </div>
              </BaseCard>

              <BaseCard
                v-if="phoneMask"
                rounded="lg"
                class="flex items-center gap-3 p-4"
                @click="changeChannel('phone')"
              >
                <div
                  class="dark:bg-muted-900 border-muted-200 dark:border-muted-800 flex size-12 items-center justify-center rounded-xl border bg-white"
                >
                  <Icon name="fa-solid:phone-alt" class="text-muted-800 dark:text-muted-100 size-5" />
                </div>
                <div>
                  <BaseHeading as="h4" size="sm" weight="light" lead="tight" class="text-muted-800 dark:text-white">
                    <span>{{ $t('_pages._login._step3.resendByPhone') }}</span>
                  </BaseHeading>
                  <BaseParagraph size="xs">
                    <span class="text-muted-400">{{ phoneMask }}</span>
                  </BaseParagraph>
                </div>
                <div class="ms-auto flex items-center">
                  <BaseButtonIcon rounded="lg" muted class="scale-75">
                    <Icon name="lucide:arrow-right" class="size-5" />
                  </BaseButtonIcon>
                </div>
              </BaseCard>
            </div>

            <div class="mb-6 grid gap-0 sm:grid-cols-3">
              <hr class="border-muted-200 dark:border-muted-700 mt-3 hidden border-t sm:block" />
              <span class="dark:bg-muted-800 text-muted-400 relative top-0.5 bg-white text-center font-sans text-sm">
                {{ $t('wb.moreInfo') }}
              </span>
              <hr class="border-muted-200 dark:border-muted-700 mt-3 hidden border-t sm:block" />
            </div>

            <div class="flex flex-col mt-4 text-center gap-2">
              <I18nT tag="p" keypath="_pages._login.comments" scope="parent" class="text-sm text-center">
                <template #helpCenter>
                  <a
                    class="underline font-bold dark:text-primary-950"
                    href="https://suporte.winx.ai/hc/pt-br/requests/new?ticket_form_id=27020495830036"
                    target="_blank"
                  >
                    {{ $t('wb.helpCenter') }}
                  </a>
                </template>
              </I18nT>
            </div>
          </BaseCard>

          <BaseParagraph size="sm" class="mt-2 text-center" weight="bold">
            {{ $t('_pages._login.footer') }}
          </BaseParagraph>
        </div>
      </div>
    </div>
  </LayoutAuth>
</template>

<script setup lang="ts">
  import { useAuth, coverUp, useHandlingError, useI18n, ref, useRouter, useRoute, useNotify } from '#imports';

  definePageMeta({
    title: '_pages._login.title',
    layout: false,
    auth: false,
  });

  const refreshIcons = ref({
    email: 0,
    phone: 0,
    document: 0,
  });

  const router = useRouter();
  const { method } = useRoute().query;

  const { startLogin, login } = useAuth();
  const { locale, t } = useI18n();
  const { coverEmail, coverPhone } = coverUp();

  const loading = ref(false);
  const twoFaMode: Ref<'email' | 'phone' | 'document'> = ref('email');

  watch(twoFaMode, (value) => {
    if (value === 'email') {
      refreshIcons.value.email++;
    } else if (value === 'phone') {
      refreshIcons.value.phone++;
    } else if (value === 'document') {
      refreshIcons.value.document++;
    }
  });

  // Chave de Acesso(Email, Telefone ou CPF)
  const accessKey = ref('');

  // Código de Acesso(Ex: 123456)
  const code = ref<string>('');

  const { show } = useNotify();

  const values = reactive({
    email: '',
    phone: '',
  });

  const emailMask = ref('');
  const phoneMask = ref(null);

  const currentStep = ref(1);
  const codeLength = ref(6);

  const isDigitsFull = computed(() => {
    return code.value.length === codeLength.value;
  });

  if (method) {
    currentStep.value = 2;

    if (method === 'phone') {
      twoFaMode.value = 'phone';
    } else if (method === 'document') {
      twoFaMode.value = 'document';
    }
  }

  function onMaska(event) {
    if (event.detail?.completed) {
      accessKey.value = event.detail?.unmasked;
    }
  }

  function goToStep(n: number) {
    if (n < 1) {
      currentStep.value = 1;
    } else if (n > 3) {
      currentStep.value = 3;
    } else {
      currentStep.value = n;
    }
  }

  async function changeChannel(channel: 'phone' | 'email') {
    loading.value = true;

    try {
      await startLogin({
        document: values[channel],
        type: channel,
        language: locale.value,
      });

      show({
        message: t('_pages._login.resendingAccess', { channel: 'wb.' + channel }),
        color: 'success',
      });
    } catch (e) {
      await useHandlingError(e);
    } finally {
      loading.value = false;
    }
  }

  async function startSignIn() {
    loading.value = true;

    try {
      const response = await startLogin({ document: accessKey.value, type: twoFaMode.value, language: locale.value });

      if (!response) return;

      const { email, phone } = response;

      emailMask.value = email ? coverEmail(email) : '';
      phoneMask.value = phone ? coverPhone(phone) : null;

      values.email = email;
      values.phone = phone;

      currentStep.value = 3;

      loading.value = false;
    } catch (e) {
      await useHandlingError(e);
      loading.value = false;
    }
  }

  async function signIn() {
    loading.value = true;

    try {
      await login(
        { document: accessKey.value, type: twoFaMode.value, code: code.value, language: locale.value },
        false
      );

      await router.push('/redirect');
    } catch (e) {
      await useHandlingError(e);
      code.value = '';
      loading.value = false;
    }
  }
</script>

<style scoped>
  .scale-up-center {
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  @keyframes scale-up-center {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
